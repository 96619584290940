import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import useTheme from 'selectors/use-theme';
import styles from './Separator.css';

export default function Separator() {
  useStyles(styles);
  const theme = useTheme();

  /* eslint css-modules/no-unused-class: off */
  return (
    <span
      className={[styles.separator, styles[`${theme}-separator`]].join(' ')}
    />
  );
}
