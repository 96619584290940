import * as types from 'types/player';
import { getADSRArray, parseTimeSignature } from 'components/Settings/helpers';

export function setSyncChanges(newStateChanges) {
  return {
    type: types.SYNC_CHANGES,
    payload: {
      ...newStateChanges,
    },
  };
}

function setTotalChords(dispatch, state) {
  const { progressionSettings } = state;
  const {
    parsedProgression,
    arpeggiateStyle,
    customArpeggio,
  } = progressionSettings;
  let totalChords = parsedProgression.length;
  if (arpeggiateStyle || customArpeggio) {
    totalChords = parsedProgression
      .map(chordObject => {
        return chordObject.chord;
      })
      .flat().length;
  }
  dispatch(setSyncChanges({ totalChords }));
}

export function clearPlayer() {
  return {
    type: types.CLEAR_PLAYER,
  };
}

export const initProgression = () => async (dispatch, getState) => {
  const state = getState();
  const { progressionSettings, player: playerState } = state;
  const parsedTimeSignature = parseTimeSignature(
    progressionSettings.timeSignature,
  );
  try {
    playerState.progressionPlayer.clear();
    playerState.progressionPlayer.initProgression({
      timeSignature: parsedTimeSignature,
      rhythm: progressionSettings.delays,
      progression: progressionSettings.parsedProgression,
      startFrom: 0,
      arpeggiate:
        progressionSettings.arpeggiateStyle ||
        progressionSettings.customArpeggio,
      // append n for tonejs timing
      chordDuration: `${progressionSettings.time}n`,
      loop: Boolean(progressionSettings.loop),
      IADSR: progressionSettings.IADSR,
      ADSR: progressionSettings.ADSR && getADSRArray(progressionSettings.ADSR),
      bpm: progressionSettings.bpm,
      velocity: progressionSettings.velocity,
    });
    setTotalChords(dispatch, state);
  } catch (e) {
    console.error(e);
  }
};
