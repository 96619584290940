import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import PropTypes from 'prop-types';

import { classNamesHelper } from 'utils';
import { HEIRARCHIES, PRIMARY } from 'design-system/inventory/heirarchy';
import useTheme from 'selectors/use-theme';
import styles from './Typography.css';

/* eslint css-modules/no-unused-class: off */
export function Text({ heirarchy, children }) {
  useStyles(styles);
  const appTheme = useTheme();

  return (
    <span
      className={classNamesHelper([
        styles.text,
        styles[`text-${heirarchy}`],
        styles[`${appTheme}-text-${heirarchy}`],
      ])}
    >
      {children}
    </span>
  );
}

Text.propTypes = {
  children: PropTypes.node.isRequired,
  heirarchy: PropTypes.oneOf(HEIRARCHIES).isRequired,
};

export function PrimaryHierarchyText({ children }) {
  return <Text heirarchy="primary">{children}</Text>;
}

PrimaryHierarchyText.propTypes = {
  children: PropTypes.node.isRequired,
};

export function SecondaryHierarchyText({ children }) {
  return <Text heirarchy="secondary">{children}</Text>;
}

SecondaryHierarchyText.propTypes = {
  children: PropTypes.node.isRequired,
};

export function TertiaryHierarchyText({ children }) {
  return <Text heirarchy="tertiary">{children}</Text>;
}

TertiaryHierarchyText.propTypes = {
  children: PropTypes.node.isRequired,
};

export function PlaceholderHierarchyText({ children }) {
  return <Text heirarchy="placeholder">{children}</Text>;
}

PlaceholderHierarchyText.propTypes = {
  children: PropTypes.node.isRequired,
};

export function DisabledHierarchyText({ children }) {
  return <Text heirarchy="disabled">{children}</Text>;
}

DisabledHierarchyText.propTypes = {
  children: PropTypes.node.isRequired,
};

export function Title({ children }) {
  useStyles(styles);
  const appTheme = useTheme();

  return (
    <span
      className={classNamesHelper([styles.title, styles[`${appTheme}-title`]])}
    >
      {children}
    </span>
  );
}

Title.propTypes = {
  children: PropTypes.node.isRequired,
};

export function Paragraph({ heirarchy, children }) {
  useStyles(styles);
  const appTheme = useTheme();

  return (
    <p
      className={classNamesHelper([
        styles.text,
        styles.paragraph,
        styles[`paragraph-${heirarchy}`],
        styles[`${appTheme}-paragraph-${heirarchy}`],
      ])}
    >
      {children}
    </p>
  );
}

Paragraph.propTypes = {
  children: PropTypes.node.isRequired,
  heirarchy: PropTypes.oneOf(HEIRARCHIES).isRequired,
};

export function H1({ children }) {
  useStyles(styles);
  const appTheme = useTheme();

  return (
    <h1
      className={classNamesHelper([
        styles.heading,
        styles.h1,
        styles[`${appTheme}-heading`],
      ])}
    >
      {children}
    </h1>
  );
}

H1.propTypes = {
  children: PropTypes.node.isRequired,
};

export function H2({ children }) {
  useStyles(styles);
  const appTheme = useTheme();

  return (
    <h2
      className={classNamesHelper([
        styles.heading,
        styles.h2,
        styles[`${appTheme}-heading`],
      ])}
    >
      {children}
    </h2>
  );
}

H2.propTypes = {
  children: PropTypes.node.isRequired,
};

export function H3({ children, hierarchy }) {
  useStyles(styles);
  const appTheme = useTheme();

  return (
    <h3
      className={classNamesHelper([
        styles.heading,
        styles.h3,
        styles[`heading-hierarchy-${hierarchy}`],
        styles[`${appTheme}-heading`],
        styles[`${appTheme}-heading-hierarchy-${hierarchy}`],
      ])}
    >
      {children}
    </h3>
  );
}

H3.propTypes = {
  children: PropTypes.node.isRequired,
  hierarchy: PropTypes.oneOf(HEIRARCHIES),
};

H3.defaultProps = {
  hierarchy: PRIMARY,
};

export function H4({ children }) {
  useStyles(styles);
  const appTheme = useTheme();

  return (
    <h4
      className={classNamesHelper([
        styles.heading,
        styles.h4,
        styles[`${appTheme}-heading`],
      ])}
    >
      {children}
    </h4>
  );
}

H4.propTypes = {
  children: PropTypes.node.isRequired,
};

export function H5({ children }) {
  useStyles(styles);
  const appTheme = useTheme();

  return (
    <h5
      className={classNamesHelper([
        styles.heading,
        styles.h5,
        styles[`${appTheme}-heading`],
      ])}
    >
      {children}
    </h5>
  );
}

H5.propTypes = {
  children: PropTypes.node.isRequired,
};

export function H6({ children }) {
  useStyles(styles);
  const appTheme = useTheme();

  return (
    <h6
      className={classNamesHelper([
        styles.heading,
        styles.h6,
        styles[`${appTheme}-heading`],
      ])}
    >
      {children}
    </h6>
  );
}

H6.propTypes = {
  children: PropTypes.node.isRequired,
};
