import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
// external-global styles must be imported in your JS.
// import normalizeCss from 'normalize.css';
// import normalizeCss from 'normalize.css';
import nprogressCss from 'nprogress/nprogress.css';
import LoadingView from 'components/LoadingView';
import globalCss from 'components/global.css';
import Header from 'components/Header';
import Footer from 'components/Footer';
import styles from './Main.css';

export default function Main({ children }) {
  useStyles(styles, globalCss, nprogressCss);
  const { isLoading } = useSelector(state => state.app);
  /* eslint css-modules/no-unused-class: off */
  return (
    <div className={styles.container}>
      <main className={styles['main-container']}>
        <Header />
        {isLoading && <LoadingView />}
        {children}
        <Footer />
      </main>
    </div>
  );
}

Main.propTypes = {
  children: PropTypes.node.isRequired,
};
