import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import PropTypes from 'prop-types';
import useTheme from 'selectors/use-theme';
import { classNamesHelper } from 'utils';
import styles from './Message.css';

export default function Message({ children, messageType, isNotification }) {
  useStyles(styles);
  const theme = useTheme();
  /* eslint css-modules/no-unused-class: off */
  return (
    <p
      className={classNamesHelper([
        styles.message,
        styles[messageType],
        isNotification ? styles[`notification-${messageType}`] : '',
        styles[theme],
      ])}
    >
      {children}
    </p>
  );
}

Message.propTypes = {
  children: PropTypes.node.isRequired,
  messageType: PropTypes.string.isRequired,
  isNotification: PropTypes.bool,
};

Message.defaultProps = {
  isNotification: false,
};
