import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import { BASE_ROUTE_DISCOVER } from 'types/app';
import { PLACEHOLDER } from 'design-system/inventory/heirarchy';
import version from 'version.json';

// import DarkModeSwitch from 'components/DarkModeSwitch';
import Separator from 'components/Separator';
import { Text } from 'components/Common/Typography';

import styles from './Footer.css';
import Link from '../Link';

export default function Footer() {
  useStyles(styles);
  const year = new Date();
  return (
    <footer className={styles.container}>
      <Text heirarchy={PLACEHOLDER}>
        <small>© Composer Studio {year.getFullYear()}</small>
      </Text>
      <Separator />
      <Text heirarchy={PLACEHOLDER}>
        <small>{`v${version.version}`}</small>
      </Text>
      <Separator />
      <Link to="/">
        <Text heirarchy={PLACEHOLDER}>
          <small>Home</small>
        </Text>
      </Link>
      <Separator />
      <Link to={BASE_ROUTE_DISCOVER}>
        <Text heirarchy={PLACEHOLDER}>
          <small>Discover</small>
        </Text>
      </Link>
      {/* <Separator /> */}
      {/* <DarkModeSwitch labelSize="small" /> */}
    </footer>
  );
}
