// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1qRCL{height:70px;-ms-flex-pack:justify;justify-content:space-between;padding:.2em 0;margin-bottom:var(--size-static-50)}._1qRCL,._2AtFI{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center}._2jKwz{height:45px;margin-right:50px}@media (max-width:768px){._1qRCL{height:100px;-ms-flex-align:start;align-items:flex-start;padding:0}}@media (max-width:480px){._1qRCL{-ms-flex-direction:column;flex-direction:column}._2AtFI{width:100%;-ms-flex-pack:justify;justify-content:space-between}}", ""]);
// Exports
exports.locals = {
	"header": "_1qRCL",
	"logo-container": "_2AtFI",
	"logo": "_2jKwz"
};
module.exports = exports;
