// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root{--label-font-size:14px;--test-color:#fff}._1QYlz{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;align-items:center;-ms-flex-pack:justify;justify-content:space-between;width:100%;padding:.2em .5em}._3vZVG{margin-right:.5em;color:hsla(0,0%,100%,.3)}._2MG54{display:inline;margin-right:.5em}@media (max-width:480px){._1QYlz{padding:0;-ms-flex-pack:justify;justify-content:space-between}._2MG54{margin-right:.1em}}", ""]);
// Exports
exports.locals = {
	"container": "_1QYlz",
	"spacer": "_3vZVG",
	"button-wrapper": "_2MG54"
};
module.exports = exports;
