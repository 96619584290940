import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import useTheme from 'selectors/use-theme';
import { setError } from 'actions/app';

import { classNamesHelper } from 'utils';

import history from 'utils/history';
import styles from './Link.css';

function isLeftClickEvent(event) {
  return event.button === 0;
}

function isModifiedEvent(event) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

export default function Link(props) {
  useStyles(styles);
  const {
    to,
    children,
    isListItem,
    state,
    cancelBubble,
    isActive,
    ...attrs
  } = props;
  const theme = useTheme();
  const dispatch = useDispatch();

  const handleClick = (linkProps, event) => {
    if (linkProps.onClick) {
      linkProps.onClick(event);
    }

    if (isModifiedEvent(event) || !isLeftClickEvent(event)) {
      return;
    }

    if (cancelBubble) {
      event.stopPropagation();
    }

    if (event.defaultPrevented === true) {
      return;
    }

    event.preventDefault();
    dispatch(setError(''));
    history.push(linkProps.to, state);
  };
  /* eslint css-modules/no-unused-class: off */
  return (
    <a
      className={classNamesHelper([
        styles.link,
        styles[theme],
        history?.location?.pathname === to && isActive && styles.isActive,
      ])}
      href={to}
      {...attrs}
      onClick={e => handleClick(props, e)}
    >
      {children}
    </a>
  );
}

Link.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  state: PropTypes.object,
  isListItem: PropTypes.bool,
  cancelBubble: PropTypes.bool,
  isActive: PropTypes.bool,
};

Link.defaultProps = {
  onClick: null,
  isListItem: false,
  state: null,
  cancelBubble: false,
  isActive: false,
};
