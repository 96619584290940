import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Link from 'components/Link';
import Portal from 'components/Portal';
import Message from 'components/Message';
import Button from 'components/Button';

import {
  create as createProgressionAction,
  clone as cloneProgressionAction,
  update as updateProgressionAction,
  newProgression,
} from 'actions/progression';
import { clearPlayer } from 'actions/player';
import { CREATE_SUCCESS, CLONE_SUCCESS } from 'types/progression';
import { STATUS_FAILURE, STATUS_SUCCESS, STATUS_PENDING } from 'types/app';
import { encodeURL, classNamesHelper, usernameDisplayLength } from 'utils';
import useTheme from 'selectors/use-theme';

import history from 'utils/history';

import styles from './Navigation.css';

export default function Navigation() {
  useStyles(styles);
  const theme = useTheme();
  const dispatch = useDispatch();

  const { user, authenticated, currentUserStatus } = useSelector(
    state => state.user,
  );
  const {
    currentProgression,
    createStatus,
    updateStatus,
    cloneStatus,
  } = useSelector(state => state.progression);
  const progressionSettings = useSelector(state => state.progressionSettings);

  const redirectToProgression = response => {
    history.replace(`/p/${response.payload.progression.slug}/edit`);
  };

  const createProgression = async () => {
    if (!progressionSettings.progressionText) return;
    // save to local storage before sending api req
    if (!authenticated) {
      history.push(`/login?redirect=${encodeURL('/')}`);
      return;
    }
    const createResponse = await dispatch(
      createProgressionAction({ progressionSettings }),
    );

    if (createResponse.type === CREATE_SUCCESS) {
      redirectToProgression(createResponse);
    }
  };

  const updateProgression = async () => {
    if (!currentProgression) return;
    // save to local storage before sending api req
    if (!authenticated) {
      history.push(`/login?redirect=${encodeURL('/')}`);
      return;
    }
    await dispatch(
      updateProgressionAction({
        slug: currentProgression.slug,
        progressionSettings,
      }),
    );
  };

  const saveProgressionHandler = () => {
    if (currentProgression) {
      updateProgression();
      return;
    }
    createProgression();
  };

  const cloneExists = () => {
    if (!currentProgression || !authenticated) {
      return false;
    }
    return !!currentProgression.cloneExists;
  };

  const isProgressionLoading = () => {
    return currentProgression?.loading;
  };

  const cloneProgressionHandler = async () => {
    if (isProgressionLoading() || !currentProgression) return;
    // save to local storage before sending api req
    if (!authenticated) {
      history.push(`/login?redirect=${encodeURL('/')}`);
      return;
    }
    // TODO show modal component so that user can navigate to cloned progression.
    if (cloneExists()) {
      window.alert('You have already cloned this progression.');
      return;
    }

    const cloneResponse = await dispatch(
      cloneProgressionAction(currentProgression.slug),
    );

    if (cloneResponse?.type === CLONE_SUCCESS) {
      redirectToProgression(cloneResponse);
    }
  };

  const isSaveProgressionAllowed = () => {
    if (isProgressionLoading() || !currentProgression || !authenticated) {
      return false;
    }
    if (currentProgression?.createdBy?._id !== user._id) {
      return false;
    }
    return true;
  };

  const newProgressionHandler = async () => {
    if (progressionSettings.progressionText && !currentProgression) {
      /* eslint no-alert: 'off' */
      const confirm = window.confirm(`The current progression will be lost.`);
      if (!confirm) {
        return;
      }
    }
    dispatch(newProgression());
    dispatch(clearPlayer());
    history.push(`/`);
  };

  const buildNotification = message => <Portal>{message}</Portal>;

  const saveSuccessNotification =
    createStatus === STATUS_SUCCESS ||
    updateStatus === STATUS_SUCCESS ||
    cloneStatus === STATUS_SUCCESS
      ? buildNotification(
          <Message messageType="success" isNotification>
            Saved!.
          </Message>,
        )
      : null;

  const saveFailedNotification =
    createStatus === STATUS_FAILURE ||
    updateStatus === STATUS_FAILURE ||
    cloneStatus === STATUS_FAILURE
      ? buildNotification(
          <Message messageType="error" isNotification>
            Something went wrong!.
          </Message>,
        )
      : null;

  const isEditorPage = () => {
    const routePath = history?.location?.pathname;
    return routePath === '/' || /\/p\/[a-zA-Z0-9_-]+\/edit/.test(routePath);
  };
  /* eslint css-modules/no-unused-class: off */
  return (
    <div
      className={classNamesHelper([styles.container, styles[theme]])}
      role="navigation"
    >
      {saveSuccessNotification}
      {saveFailedNotification}
      <div>
        {isEditorPage() && (
          <div className={styles['button-wrapper']}>
            <Button
              type="button"
              onClick={saveProgressionHandler}
              disabled={currentProgression && !isSaveProgressionAllowed()}
            >
              Save
            </Button>
          </div>
        )}
        {isEditorPage() && currentProgression && !isSaveProgressionAllowed() && (
          <div className={styles['button-wrapper']}>
            <Button
              type="button"
              onClick={cloneProgressionHandler}
              disabled={!authenticated}
            >
              Clone
            </Button>
          </div>
        )}
        <div className={styles['button-wrapper']}>
          <Button type="button" onClick={newProgressionHandler}>
            New
          </Button>
        </div>
      </div>
      <span className={styles.spacer} />
      {!authenticated && currentUserStatus !== STATUS_PENDING && (
        <>
          <Link to="/login">Log in</Link>
        </>
      )}
      {authenticated && (
        <>
          <Link to={`/${user.username}`} state={{ reRender: true }}>
            <span>{usernameDisplayLength(user.username)}</span>
          </Link>
        </>
      )}
    </div>
  );
}
