// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root{--label-font-size:14px;--test-color:#fff}._3dfAC{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;margin-top:1.5em;padding:0 .5em}@media (max-width:768px){._3dfAC{display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap}}", ""]);
// Exports
exports.locals = {
	"container": "_3dfAC"
};
module.exports = exports;
