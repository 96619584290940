import { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

export default function Portal({ portalType, children, timeout }) {
  const [element, setElement] = useState(null);
  const portalRoot = useRef(null);

  const idMap = {
    notification: 'composer-notification-root',
    modal: 'composer-modal-root',
  };

  const removeChild = () => {
    if (element && element.parentNode === portalRoot.current) {
      portalRoot.current.removeChild(element);
    }
  };

  useEffect(() => {
    portalRoot.current = document.getElementById(idMap[portalType]);
    if (portalType === 'notification') {
      setElement(document.createElement('div'));
    } else {
      setElement(portalRoot.current);
    }
  }, []);

  useEffect(() => {
    if (element && portalType === 'notification') {
      portalRoot.current.appendChild(element);
    }
  }, [element]);

  useEffect(() => {
    let timeoutId = null;
    if (element && portalType === 'notification') {
      timeoutId = setTimeout(() => {
        removeChild();
      }, timeout);
    }
    return () => {
      clearInterval(timeoutId);
      removeChild();
    };
  }, [element]);

  if (!element) {
    return null;
  }

  return ReactDOM.createPortal(children, element);
}

Portal.propTypes = {
  children: PropTypes.node.isRequired,
  timeout: PropTypes.number,
  portalType: PropTypes.string,
};

Portal.defaultProps = {
  timeout: 3000,
  portalType: 'notification',
};
