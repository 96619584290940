import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import PropTypes from 'prop-types';
import useTheme from 'selectors/use-theme';
import { classNamesHelper } from 'utils';
import styles from './Button.css';

export default function Button({ children, onClick, buttonType, disabled }) {
  useStyles(styles);
  const appTheme = useTheme();
  /* eslint css-modules/no-unused-class: off */
  return (
    <button
      disabled={disabled}
      type="button"
      className={classNamesHelper([
        styles.button,
        styles[buttonType],
        styles[`${appTheme}-${buttonType}`],
      ])}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  buttonType: PropTypes.string,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  buttonType: 'default',
  disabled: false,
};
