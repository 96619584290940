export const PRIMARY = 'primary';
export const SECONDARY = 'secondary';
export const TERTIARY = 'tertiary';
export const PLACEHOLDER = 'placeholder';
export const DISABLED = 'disabled';

export const HEIRARCHIES = [
  PRIMARY,
  SECONDARY,
  TERTIARY,
  PLACEHOLDER,
  DISABLED,
];
