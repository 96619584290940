// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root{--label-font-size:14px;--test-color:#fff}._1S7wq{border-radius:var(--size-static-25);border:1px solid var(--neutral-shade-lighter-30);padding:.5em}._2NN0i{border-color:var(--green-accent-base)}._17AaK{background-color:var(--green-accent-base)}._2TqD2{border-color:var(--primary-base)}._3zV0v{background-color:var(--primary-base);color:#fff}", ""]);
// Exports
exports.locals = {
	"message": "_1S7wq",
	"success": "_2NN0i",
	"notification-success": "_17AaK",
	"error": "_2TqD2",
	"notification-error": "_3zV0v"
};
module.exports = exports;
