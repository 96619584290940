import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import PropTypes from 'prop-types';

import useTheme from 'selectors/use-theme';

import { classNamesHelper } from 'utils';

import styles from './LoadingView.css';

export const DummyTitle = () => {
  useStyles(styles);
  return <div className={styles['dummy-title']} />;
};

export const DummyDetail = ({ isFullWidth }) => {
  useStyles(styles);
  return (
    <div
      className={styles[('dummy-detail', isFullWidth ? 'full-width' : '')]}
    />
  );
};

DummyDetail.propTypes = {
  isFullWidth: PropTypes.bool,
};

DummyDetail.defaultProps = {
  isFullWidth: false,
};

export const DummySettings = () => {
  useStyles(styles);
  return <div className={styles['dummy-settings']} />;
};

export const DummyUser = () => {
  useStyles(styles);
  return <div className={styles['dummy-user']} />;
};

export default function LoadingViewSmall() {
  useStyles(styles);
  const theme = useTheme();
  /* eslint css-modules/no-unused-class: off */
  return (
    <div className={classNamesHelper([styles['main-loading'], styles[theme]])}>
      <div className={styles.circle} />
      <div className={styles.circle} />
      <div className={styles.circle} />
    </div>
  );
}

// export default function LoadingView() {
//   useStyles(styles);
//   const theme = useTheme();
//   /* eslint css-modules/no-unused-class: off */
//   return (
//     <div className={classNamesHelper([styles.loading, styles[theme]])}>
//       <div className={classNamesHelper([styles['eyes-container']])}>
//         <div className={classNamesHelper([styles.eye, styles.animation])} />
//         <div className={classNamesHelper([styles.eye, styles.animation])} />
//       </div>
//       <div
//         className={classNamesHelper([
//           styles.nose,
//           styles.part,
//           styles.animation,
//         ])}
//       />
//       <div
//         className={classNamesHelper([
//           styles.mouth,
//           styles.part,
//           styles.animation,
//         ])}
//       />
//     </div>
//   );
// }
