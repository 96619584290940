import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';

import useTheme from 'selectors/use-theme';
import Link from '../Link';
import Navigation from '../Navigation';

import logoUrl from './composer-studio-logo.svg';
import darkLogoUrl from './composer-studio-logo-dark.svg';

import styles from './Header.css';

export default function Header() {
  useStyles(styles);
  const theme = useTheme();

  /* eslint css-modules/no-unused-class: off */
  return (
    <header className={[styles.header, styles[theme]].join(' ')}>
      <div className={styles['logo-container']}>
        <Link to="/">
          {theme === 'dark-mode' ? (
            <img
              src={darkLogoUrl}
              className={styles.logo}
              alt="Composer Studio Logo Dark"
            />
          ) : (
            <img
              src={logoUrl}
              className={styles.logo}
              alt="Composer Studio Logo"
            />
          )}
        </Link>
        <Link to="/discover" state={{ reRender: true }}>
          <span>&#9734;</span>&nbsp;Discover
        </Link>
      </div>
      <Navigation />
    </header>
  );
}
